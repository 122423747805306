import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Button from '../../../components/button/button';
import Input from '../../../components/input/input';


export const SimpleSubscribeWrapper = styled.div`
  background: ${themeGet('colors.background')};
  width: 1080px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
 
  @media ${themeGet('mediaQueries.small')}{
    flex-direction: column;
  }
`;

export const SimpleSubscribeLeft = styled.div`
  color: white;
  flex-basis: 600px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 50px;
  position: relative;

  @media ${themeGet('mediaQueries.medium')}{
    flex-basis: 273px; 
    margin-left: 60px;
  }

  @media ${themeGet('mediaQueries.small')}{
    padding-left: 0;
    padding-top: 15px;
    padding-bottom: 20px;
  }
`;

export const SimpleSubscribeTitle = styled.p`
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 0;
  font-family: ${themeGet('fontFamily.1', "'Mulish', sans-serif")};
  color: ${themeGet('colors.textColor', '#fff')};
  letter-spacing:1px;

  @media ${themeGet('mediaQueries.medium')}{
    font-size: 18px;
  }
  @media ${themeGet('mediaQueries.small')}{
    font-size: 16px;
  }
`;
export const SimpleSubscribeSubtitle = styled.div`
  line-height: 1.65em;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 20px;
  font-family: ${themeGet('fontFamily.1', "'Mulish', sans-serif")};
  color: ${themeGet('colors.textColor', '#fff')};

  @media ${themeGet('mediaQueries.medium')}{
    font-size: 16px;
    margin-bottom: 0px;
  }
  @media ${themeGet('mediaQueries.small')}{
    font-size: 14px;
    margin-bottom: 0px;
  }
`;

export const IntroWrapper = styled.div`
  background: ${themeGet('colors.background')};
  width: 1120px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
 
  @media ${themeGet('mediaQueries.small')}{
    flex-direction: column-reverse;
  }
`;

export const IntroImage = styled.div`
  width: 304px;
  height: 512px;
  margin-left: 20px;
  
  img{
    width:100%;
    height: 100%;
  }
  @media ${themeGet('mediaQueries.small')}{
    max-width: 91px;
    height: auto;
    margin: 0 auto; 
    margin-top: 35px;
  }

`;

export const IntroTitle = styled.p`
  line-height: 1.65em;
  font-size: 28px;
  font-weight: 800;
  font-family: ${themeGet('fontFamily.1', "'Mulish', sans-serif")};
  color: ${themeGet('colors.textColor', '#fff')};
  letter-spacing:1.5px;
  padding-top: 12px;
  margin-bottom: 10px;

  @media ${themeGet('mediaQueries.medium')}{
    font-size: 29px;
  }
  @media ${themeGet('mediaQueries.small')}{
    font-size: 24px;

  }
`;

export const Description = styled.div`
  color: ${themeGet('colors.textColor', '#fff')};
  font-size: 18px;
  font-family: ${themeGet('fontFamily.1', "'Mulish', sans-serif")};
  line-height: 1.5;
  padding-left: 10px;
  padding-bottom: 10px;
  margin-bottom: 5px;

  @media ${themeGet('mediaQueries.small')}{
   font-size: 18px;
  }

`;

export const IntroInfo = styled.div`
  flex-basis: 800px;
  padding-top: 30px;
  padding-bottom: 80px;
  padding-left: 20px ;
  margin-right: 50px;
  position: relative;

  @media ${themeGet('mediaQueries.medium')}{
    flex-basis: 373px; 
    margin-left: 60px;
  }

  @media ${themeGet('mediaQueries.small')}{
    padding-left: 0;
    padding-top: 35px;
    padding-bottom: 50px;
  }
`;

export const IntroFormWrapper = styled.form`
  padding: 15px 5px;
  border-radius: 4px;
  max-width: 600px;
`;

export const IntroFormRow = styled.div`
  display: flex;
  background: white;
  padding: 15px 5px;
  border-radius: 4px;

  @media ${themeGet('mediaQueries.small')}{
   flex-direction: column;
  }
`;

export const IntroButton = styled(Button)`
  background:${themeGet('colors.primary')};
  font-family: ${themeGet('fontFamily.2', 'serif')};
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  border-radius: 2px;
  text-transform: capitalize;
  padding: 10px;
  flex-basis: 130px; 

  @media ${themeGet('mediaQueries.small')}{
    flex-basis: 100%;
  }
`

export const IntroInput = styled(Input)`
  flex-grow: 1;
  input{
    font-family: ${themeGet('fontFamily.2', 'serif')};
    color: #B7B7B7 !important;
    /* padding: 0 !important; */
    border: none !important;
    font-size: 20px !important;
  }
  
  input::placeholder{
    font-size: 20px;
    font-family: ${themeGet('fontFamily.2', 'serif')};
    text-transform: capitalize;
    color: #B7B7B7 !important;
  }
`

export const ArrowContainer = styled.div`
  width: 30px;
  height: 88px;
  position: absolute;
  left: -35px;
  top: 45%;
  img{
    filter: grayscale(0%) blur(0px) brightness(100%) sepia(0%) invert(100%) saturate(100%) contrast(30%) hue-rotate(178deg);
  }
  
  @media ${themeGet('mediaQueries.medium')}{
    left:-15px;
    top:58%;
  }
  @media ${themeGet('mediaQueries.small')}{
    display: none;
  }
`


// Second section


export const FeatureWrapper = styled.div`
  background: white;
  padding-top: 30px;
  padding-bottom: 60px;

  @media ${themeGet('mediaQueries.small')}{
    padding-bottom: 30px;
  }
`;

export const FeatureContainer = styled.div`
  max-width: 1080px;
  margin: 0 auto;

  @media ${themeGet('mediaQueries.medium')}{
    padding-left: 20px;
    padding-right: 20px;
  }

`

export const FeatureTitle = styled.div`
  text-align: center;
  font-size: 39px;
  line-height: 1.65em;
  font-weight: 800;
  font-family: ${themeGet('fontFamily.1', "'Mulish', sans-serif")};
  letter-spacing:2px;
  padding-top: 12px;

  @media ${themeGet('mediaQueries.medium')}{
    font-size: 29px;
  }

  @media ${themeGet('mediaQueries.small')}{    
    &:nth-child(3){
      display:none;
    }
  }
`

export const FeatureImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;

  @media ${themeGet('mediaQueries.small')}{    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

`

export const FeatureImage = styled.div` 
  width: 200px;
  height: 106px;

  &:nth-child(2){
    width: 256px;
    height: 98px;
  }

  @media ${themeGet('mediaQueries.medium')}{
    &:not(:last-child){
      margin-right: 40px;
    }
  }

  @media ${themeGet('mediaQueries.small')}{
    max-width: 350px;
    width: 100%; 
    height: auto;   
    &:not(:last-child){
      margin-right: 0;
      margin-bottom: 40px;
    }
  }

`
export const TestimonialWrapper = styled.div`
  display: flex;

  @media ${themeGet('mediaQueries.small')}{
    display: none;
  }
`

export const TestimonialTitle = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`


export const TestimonialImage  = styled.div`

  margin-right: 30px;
  margin-top: 40px;

  width: 140px;
  height: 140px;
  img{
    border-radius: 50%;
  }
`

export const TestimonialQuoteImage = styled.div`
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  margin-right: 10px;
`

export const TestimonialText = styled.div`
  h4{
    display: flex;
    align-items: center;
    line-height: 1.2em;
    margin-bottom: 60px;
    margin-top: 10px;
  }

  p{

    font-size: 18px;
    color: rgb(42, 42, 42); 
    padding-bottom: 15px; 
  }

  span{
    font-size: 18px;
    color: black;
    font-weight: 800; 
    a{
    font-weight: 400; 
      color: ${themeGet('colors.primary', "yellow")};
    }
  }
`;
