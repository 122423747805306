import * as React from 'react';
import PersonalBlogWrapper from './style';
import Subscribe from './subscribe/subscribe';

type PersonalBlogProps = {};

const PersonalBlog: React.FunctionComponent<PersonalBlogProps> = ({
  ...props
}) => {
  return (
    <PersonalBlogWrapper {...props}>
      <Subscribe />
    </PersonalBlogWrapper>
  );
};

export default PersonalBlog;
