import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { themeGet } from '@styled-system/theme-get';

import Background from '../../../components/blog/Background';
import Button from '../../../components/blog/Button';
import Center from '../../../components/blog/Center';
import { Grid, Column } from '../../../components/blog/Grid';
import Image from 'gatsby-image';
import ImageFile from '../../../components/blog/Image';
import FormHoneypot from '../../../components/forms/honeypot';
import Broadcast from '../../../components/blog/Broadcast'
import {
  IntroWrapper,
  IntroImage,
  IntroTitle,
  Description,
  IntroInfo,
  IntroFormWrapper,
  IntroFormRow,
  IntroButton,
  IntroInput,
  ArrowContainer,
  FeatureWrapper,
  FeatureTitle,
  FeatureImageWrapper,
  FeatureImage,
  TestimonialWrapper,
  FeatureContainer,
  TestimonialTitle,
  TestimonialImage,
  TestimonialQuoteImage,
  TestimonialText,
  SimpleSubscribeWrapper, SimpleSubscribeLeft, SimpleSubscribeTitle, SimpleSubscribeSubtitle,
} from './style';


type IntroProps = {
  isIndexPage?: boolean
};


const Subscribe: React.FunctionComponent<IntroProps> = ({
  isIndexPage
}) => {
  const Data = useStaticQuery(graphql`
    query {
      ruben_logo: file(absolutePath: { regex: "/ruben-logo-left.webp/" }) {
        childImageSharp {
          fluid(maxWidth: 354, maxHeight: 512, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      gamasutra: file(absolutePath: { regex: "/gamasutra.webp/" }) {
        childImageSharp {
          fluid(maxWidth: 768, maxHeight: 232, quality: 100, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
      unity: file(absolutePath: { regex: "/unity.png/" }) {
        childImageSharp {
          fluid(maxWidth: 1402, maxHeight: 512, quality: 100, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
      testimonial: file(absolutePath: { regex: "/testimonial-intro.webp/" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 200, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      gamedev: file(absolutePath: { regex: "/gamedev.webp/" }) {
        childImageSharp {
          fluid(maxWidth: 368, maxHeight: 74, quality: 100, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
      xrb: file(absolutePath: { regex: "/logo-xrb.png/" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 430, quality: 100, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
      arrow: file(absolutePath: { regex: "/intro-arrow.png/" }) {
        childImageSharp {
          fluid(maxWidth: 30, maxHeight: 88, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  const RubenLogoImage = Data.ruben_logo.childImageSharp.fluid;
  const ArrowImage = Data.arrow.childImageSharp.fluid;
  const GamasutraImage = Data.gamasutra.childImageSharp.fluid;
  const UnityImage = Data.unity.childImageSharp.fluid;
  const GamedevImage = Data.gamedev.childImageSharp.fluid;
  const XrbImage = Data.xrb.childImageSharp.fluid;

  return (
    <div style={{background:'#2A2A2A'}}>
      <Broadcast/>
      {
        !isIndexPage && (
          <SimpleSubscribeWrapper>
            <SimpleSubscribeLeft>
              <SimpleSubscribeTitle>🚀 DOUBLE YOUR PERFORMANCE WITH RUBEN</SimpleSubscribeTitle>
              <SimpleSubscribeSubtitle>
                The Unity Performance Taskforce gives you weekly lessons on: 
                <ul>
                  <li>[PRO] Become an efficient developer</li>
                  <li>[CPU] Optimize your gameplay & draw calls</li>
                  <li>[GPU] Better graphics — cheaper</li>
                  <li>[MEM] Lower memory usage, crashes, loading times, package size</li>
                </ul>
                </SimpleSubscribeSubtitle>
            </SimpleSubscribeLeft>
            <Center>
              <Button link="/" label="CLICK HERE TO GET YOUR FREE MONTH" color="#ed8800" small/>
            </Center>
          </SimpleSubscribeWrapper>
          )
      }
      {
        isIndexPage && (
          <IntroWrapper>
            <IntroInfo>
              <IntroTitle>
              🚀 FIX YOUR PERFORMANCE WITH RUBEN
              </IntroTitle>
              <Description>
                Get <strong><a href="https://consulting.thegamedev.guru/" style={{color: "white"}}>consulting</a></strong> from Ruben on the four performance pillars: 
                <ul>
                  <li>[PRO] Optimize your development team & product to increase your business revenue</li>
                  <li>[CPU] Improve your gameplay performance</li>
                  <li>[GPU] Render better graphics, just cheaper</li>
                  <li>[MEM] Lower memory usage, crashes, loading times, ANR to improve your metrics</li>
                </ul>
                <br/>
                Enter your email below to gain access to your first PerformanceTaskforce month for free:
              </Description>
            <IntroFormWrapper method="post" action="https://signup.thegamedev.guru">
              <IntroFormRow>
                <IntroInput name="email" type="email" placeholder="email address" required/>
                <input readOnly type="text" name="tag" style={{ display: "none" }} value="lead,newsletter" />
                <IntroButton title="sign up" type="submit"/>
              </IntroFormRow>
              <FormHoneypot />
              <div style={{marginTop: 10}}>
                <input name="consent" type="checkbox" style={{transform: "scale(1.5)"}} required />
                <label htmlFor="consent" style={{paddingLeft:10, color: 'white'}}>I agree with the <a href="https://www.iubenda.com/privacy-policy/41442685" style={{color: 'white', textDecoration: 'underline'}}>privacy policy</a> and to receive regular emails with performance tips and promotional offers on optimization*</label>
              </div>
              <div style={{marginTop: 40}}>
                <Link to="/blog" style={{paddingLeft:10, color: 'white'}}>✖️ <u>No thanks, continue to the blog</u></Link>
              </div>
            </IntroFormWrapper>
            <ArrowContainer>
              <Image fluid={ArrowImage} alt="Arrow image" />
            </ArrowContainer>
          </IntroInfo>
          <IntroImage>
            <Image fluid={RubenLogoImage} alt="The Gamedev guru" />
          </IntroImage>
          </IntroWrapper>
        )
      }
      {
        isIndexPage && (
          <FeatureWrapper>
            <FeatureContainer>  
              <FeatureTitle>
                RUBEN HAS BEEN FEATURED ON
              </FeatureTitle>
              <FeatureImageWrapper>
                <FeatureImage><Image fluid={GamasutraImage} alt="gamasutra logo" /></FeatureImage>
                <FeatureImage><Image fluid={UnityImage} alt="unity logo" /></FeatureImage>
                <FeatureImage><Image fluid={XrbImage} alt="XRB" /></FeatureImage>
                <FeatureImage><Image fluid={GamedevImage} alt="gamedev logo" /></FeatureImage>
              </FeatureImageWrapper>
              

              <FeatureTitle>WHAT DEVELOPERS ARE SAYING</FeatureTitle>
              <ImageFile file="testimonial-tape.png" maxWidth="700px" alt="Tape to Tape" global greyscale />
              <ImageFile file="testimonial-schell-games-connor.png" maxWidth="700px" alt="testimonial Schell" global greyscale />
              <ImageFile file="testimonial-choice-provisions.png" maxWidth="700px" alt="testimonial Schell" global greyscale />

              <Grid columnSize="2" columnGap="5px" bottomGap="5px" verticalCenter>
                <Column><ImageFile file="testimonial-shainiel.png" maxWidth="600px" alt="testimonial Shainiel" global greyscale /></Column>
                <Column><ImageFile file="testimonial-sourav.png" maxWidth="600px" alt="testimonial sourav" global greyscale /></Column>
              </Grid>
              
              <ImageFile file="testimonial-ferhan.png" maxWidth="800px" alt="testimonial ferhan" greyscale global />
              <ImageFile file="testimonial-sourav.png" maxWidth="800px" alt="testimonial sourav" greyscale global />
              <ImageFile file="testimonial-jason-harrison.png" maxWidth="700px" alt="testimonial Jason" greyscale global />
              <ImageFile file="testimonial-jerome.png" maxWidth="800px" alt="testimonial Jerome" greyscale global />

            </FeatureContainer>
          </FeatureWrapper>
        )
      }
    </div>
  );
};

Subscribe.defaultProps = {
  isIndexPage: true,
};

export default Subscribe;
