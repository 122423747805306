import React from 'react';
import {
    FormHoneypotInput,
} from './honeypot.style';


type IntroProps = {
  isSecondPart?: boolean
};


const FormHoneypot: React.FunctionComponent<IntroProps> = () => {
  return (
    <FormHoneypotInput name="tgg_honeypot" />
  );
};


export default FormHoneypot;
